<template>
  <div :style="computedHeight">
    <!-- Only display headers and footers when not on the login page -->
    <HeaderDefault/>

    <router-view v-slot="{ Component, route }">
      <component
        :is="Component"
        v-bind="route.props"
      />
    </router-view>
  </div>
</template>

<script>
// Importing mobile-specific components
import HeaderDefault from "@/components/HeaderDefault.vue";

import { useDisplay } from "vuetify";

export default {
  components: {
    HeaderDefault
  },
  data() {
    return {
      showLeftPanel: false,
    }
  },
  methods: {
    computedHeight() {
      const display = useDisplay();
      if (
        display.smAndUp.value ||
        this.$route.name === "Landing"
      ) {
        return "max-height: 100vh";
      } else {
        return "max-height: 95vh";
      }
    }
  },
};
</script>

<style>
html,
body {
  margin: 0px;
  overflow: hidden; /* Prevents scrolling */
  font-family: "Noto Sans KR", sans-serif; /* Using Noto Sans KR as the base font */
  background-color: #FBFCFC;
  color: #000000; /* Light text for dark mode */
  max-height: 100vh; /* Set the maximum height */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.loading-circular {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>